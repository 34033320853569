import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';

import Loader from 'components/ui-kit/loader';
import Label from '../label';
import * as S from './styles';

export default function Fieldbox(props) {
  const {
    field,
    field: {
      name,
      value,
    },
    form,
    label,
    onBlur,
    onFocus,
    children,
    isLabelUp,
    isLoading,
    isTextareaValue,
    isGap,
    isIconClickable,
    placeholder,
    disabled,
    hideLabel,
    ...restProps
  } = props;

  const [isFocused, setFocus] = useState(false);

  const touch = getIn(form.touched, name);
  const error = getIn(form.errors, name);
  const isError = Boolean(error && touch);

  const eventHandlers = {
    onBlur(event) {
      setFocus(false);

      if (event) {
        if (typeof field.onBlur === 'function') {
          field.onBlur(event);
        }

        if (typeof onBlur === 'function') {
          onBlur(event);
        }
      }
    },
    onFocus(event) {
      setFocus(true);

      if (event) {
        if (typeof field.onFocus === 'function') {
          field.onFocus(event);
        }

        if (typeof onFocus === 'function') {
          onFocus(event);
        }
      }
    },
  };

  const childrenWithProps = React.Children.map(children, (child) => (React.cloneElement(child, {
    ...field,
    ...restProps,
    ...eventHandlers,
    isFocused,
    disabled,
  })));

  return (
    <S.Fieldbox
      isError={isError}
      isGap={isGap}
      isFocused={isFocused}
      disabled={disabled}
    >
      {childrenWithProps}
      <Label
        isLabelUp={isLabelUp || isFocused || !!value}
        label={label}
        hideLabel={hideLabel}
        disabled={disabled}
        fieldName={name}
        isError={isError}
        placeholder={placeholder}
      />
      {isLoading && <S.FieldLoader><Loader /></S.FieldLoader>}
      {isError && <S.ErrorMessage>{error}</S.ErrorMessage>}
    </S.Fieldbox>
  );
}

Fieldbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.instanceOf(Date),
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      PropTypes.arrayOf(PropTypes.string),
    ]),
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    touched: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }).isRequired,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.node,
  label: PropTypes.string,
  helpMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  isLabelUp: PropTypes.bool,
  hideLabel: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  size: PropTypes.oneOf(['small', 'datepicker', 'default', 'large', 'responsive']),
  isGap: PropTypes.bool,
  isTextareaValue: PropTypes.bool,
  isIconClickable: PropTypes.bool,
};

Fieldbox.defaultProps = {
  size: 'default',
  isGap: true,
  label: null,
  helpMessage: '',
  placeholder: '',
  prefix: null,
  hint: null,
  icon: null,
  children: null,
  disabled: false,
  isLabelUp: false,
  hideLabel: false,
  isLoading: false,
  onBlur: null,
  onFocus: null,
  isTextareaValue: false,
  isIconClickable: false,
};
