import { useApp, useModal } from 'hooks';
import Button from 'components/ui-kit/buttons/button';
import RequestWorkModal from 'components/request-work-modal';
import * as S from './styles';

export default function RequestWork() {
  const { portal, isPreviewMode } = useApp();
  const { showModal } = useModal();
  const clickHandler = isPreviewMode ? () => {} : () => showModal(RequestWorkModal);
  if (!portal) {
    return <S.InfoBox />;
  }

  return (
    <S.InfoBox>
      <S.ImageContainer>
        <S.BookIcon />
      </S.ImageContainer>

      <S.Text>
        Quickly book
        <br />
        service online
      </S.Text>
      <Button onClick={clickHandler} color="primary">
        Book Online
      </Button>
    </S.InfoBox>
  );
}
