import * as yup from 'yup';

import { bookingFormFieldType } from 'constants/appointments';

const defaultRequiredMessage = 'This field is required';
export default function getValidationSchema(fields) {
  const customFieldsSchema = fields.reduce((acc, { name, isRequired, fieldType }) => {
    let fieldValidation;
    if (fieldType === bookingFormFieldType.checkbox) {
      fieldValidation = yup.array().of(yup.string());

      if (isRequired) {
        fieldValidation = fieldValidation
          .test(
            name,
            defaultRequiredMessage,
            ((array) => array.some((x) => Boolean(x) && x !== 'false')),
          );
      }
      return {
        ...acc,
        [name]: fieldValidation,
      };
    }
    fieldValidation = yup.string().max(255, 'Value is too long - should be 255 chars maximum');

    if (fieldType === bookingFormFieldType.textArea) {
      fieldValidation.min(2, 'Value is too short - should be two chars minimum');
    }
    if (isRequired) { fieldValidation = fieldValidation.required(defaultRequiredMessage); }
    return {
      ...acc,
      [name]: fieldValidation,
    };
  }, {});

  const validationSchema = yup.object().shape({
    date: yup.date().required('Date is a required field'),
    time: yup.string(),
    isAddressInArea: yup.boolean().required(),
    geoData: yup.object().shape({
      latitude: yup.number().required(),
      longitude: yup.number().required(),
    }),
    address: yup.string()
      .min(2, 'Property must be at least 2 characters')
      .max(255, 'Property must be at most 255 characters')
      .required('Property is a required field')
      .when('geoData', {
        is: (geoData) => !geoData.latitude || !geoData.longitude,
        then: (schema) => schema.test(
          'Invalid value',
          'Invalid value of the specified property',
          () => false,
        ),
      })
      .when('isAddressInArea', {
        is: (isAddressInArea) => { return !isAddressInArea; },
        then: (sch) => sch.test(
          'Invalid value',
          'Unfortunately, we don’t provide our service at the specified property',
          () => false,
        ),
      }),
    unitNumber: yup.string().max(10, 'Unit/Suite/Apt. Number should be 10 characters maximum'),
    ...customFieldsSchema,
  });
  return validationSchema;
}
