import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useModal } from 'hooks';
import * as S from './styles';

export default function Modal({
  children,
  title,
  isClosable,
  showCloseButton,
  size,
  marginBottom,
  toolbar,
  headerGridSettings,
}) {
  const { hideModal, isExit } = useModal();
  const backdropRef = useRef();

  function outsideClick(e) {
    if (e.target === backdropRef.current && isClosable) {
      hideModal();
    }
  }

  function keyboardCloseListener(e) {
    if (e.key === 'Escape') {
      e.stopPropagation();
      hideModal();
    }
  }

  useEffect(() => {
    document.addEventListener('click', outsideClick);
    document.addEventListener('keydown', keyboardCloseListener);

    return () => {
      document.removeEventListener('click', outsideClick);
      document.removeEventListener('keydown', keyboardCloseListener);
    };
  });

  const isHeaderShown = !!title || !!toolbar;

  return (
    <S.Backdrop ref={backdropRef} className={isExit ? 'exit' : null}>
      <S.Modal size={size} className={isExit ? 'exit' : null}>
        {isHeaderShown && (
          <S.Header marginBottom={marginBottom} gridSettings={headerGridSettings}>
            <S.Title>{title}</S.Title>
            {toolbar}
            {showCloseButton && (
              <S.Close onClick={hideModal}>
                <CloseIcon fill="black" />
              </S.Close>
            )}
          </S.Header>
        )}
        {(showCloseButton && !isHeaderShown) && (
          <S.Close absolute onClick={hideModal}>
            <CloseIcon fill="black" />
          </S.Close>
        )}
        <S.Body>
          {children}
        </S.Body>
      </S.Modal>
    </S.Backdrop>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  title: PropTypes.string,
  isClosable: PropTypes.bool,
  size: PropTypes.string,
  marginBottom: PropTypes.oneOf([
    'none',
    'base',
    'medium',
    'large',
    'extraLarge',
    'small',
    'extraSmall',
  ]),
  toolbar: PropTypes.node,
  showCloseButton: PropTypes.bool,
  headerGridSettings: PropTypes.shape({
    templateColumns: PropTypes.string,
    columnGap: PropTypes.string,
  }),
};

Modal.defaultProps = {
  title: '',
  size: '5/9',
  isClosable: true,
  showCloseButton: true,
  marginBottom: 'extraSmall',
  toolbar: null,
  headerGridSettings: null,
};
