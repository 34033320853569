import styled from 'styled-components';
import { ReactComponent as LogoSVG } from 'assets/icons/image/logo.svg';
import {
  spacing, defaultColor, font, fluidFont,
} from 'styles/theme';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('default')};

  & .react-formik-wizard__step-tabs {
    width: calc(100% - 24px);
    padding: 28px 5px;
  }
  & .react-formik-wizard__step-tabs__tab-list > li {
    width: 32%;
    align-items: start;
    font-family: ${font('regular')};
    font-size: 16px;
    color: ${defaultColor('dark')};
    font-weight: bold;
    text-align: left;
  }
  & .react-formik-wizard__step-tabs__tab-list > li.is-active ~ li {
    color: #dbdbdb;
  }
  & .react-formik-wizard__step-tabs__tab-list::before {
    height: 0;
  }
  & .react-formik-wizard__step-tabs__tab-list > li::before {
    width: 90% ;
    height: 8px;
    margin: 0;
    font-size: 0;
    border-radius: 4px;
    margin-bottom: -30px;
    background: ${defaultColor('dark')};
    border: none;
  }
  & .react-formik-wizard__step-tabs__tab-list > li::after {
    height: 0;
  }
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${spacing('default')};
`;

export const Label = styled.label`
  font-family: ${font('regular')};
  ${fluidFont('12px', '14px')};
  color: ${defaultColor('dark')};
  margin-bottom: -10px;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  margin: 20px;
`;

export const Text = styled.span`
  font-family: ${font('regular')};
  font-size: 14px;
  color: ${defaultColor('dark')};
`;

export const LogoImage = styled(LogoSVG)`
  width: 161px;
  height: 23px;
  margin-bottom: 4px;
`;

export const Button = styled.div`
  min-width: 82.5px;
`;
