import {
  add,
  startOfDay,
} from 'date-fns';
import { ReactComponent as JobIcon } from 'assets/icons/icon-job.svg';
import { ReactComponent as VisitIcon } from 'assets/icons/icon-visit.svg';
import { ReactComponent as EstimateIcon } from 'assets/icons/icon-estimate.svg';

export const getIcon = (type) => {
  switch (type) {
    case 0:
      return <JobIcon />;
    case 1:
      return <EstimateIcon />;
    case 2:
      return <VisitIcon />;
    default:
      return null;
  }
};

export const getColor = (type) => {
  switch (type) {
    case 0:
      return 'green';
    case 1:
      return 'blue';
    case 2:
      return 'yellow';
    default:
      return null;
  }
};

export const appointmentTypes = {
  0: 'job',
  1: 'estimate',
  2: 'visit',
};

export const appointmentBusinessTypes = {
  all: null,
  job: 0,
  estimate: 1,
  visit: 2,
};
export const bookingFormFieldType = {
  input: 0,
  date: 1,
  dropdown: 2,
  checkbox: 3,
  radio: 4,
  textArea: 5,
  location: 6,
};
export const getNextDayTitle = (date) => {
  const today = startOfDay(new Date());
  const tomorrow = add(today, { days: 1 });
  if (date === today.toISOString()) {
    return 'Today';
  }
  if (date === tomorrow.toISOString()) {
    return 'Tomorrow';
  }

  return null;
};
