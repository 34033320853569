import Modal from 'components/ui-kit/modal';
import { useModal } from 'hooks';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Button from '../button';
import * as S from './styles';

export default function ConfirmationModal({
  onConfirm,
  afterConfirm,
  onCancel,
  modalContent,
  buttonTitle,
  modalTitle,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { hideModal } = useModal();

  const handleConfirm = async () => {
    try {
      setIsSubmitting(true);
      await onConfirm();
      setIsSubmitting(false);
      hideModal();
      await afterConfirm();
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = async () => {
    try {
      setIsSubmitting(true);
      await onCancel();
      setIsSubmitting(false);
      hideModal();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal title={modalTitle}>
      {modalContent}
      <S.ButtonGroup>
        <Button
          onClick={handleCancel}
          stretched
          isLoading={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          stretched
          color="primary"
          isLoading={isSubmitting}
        >
          {buttonTitle}
        </Button>
      </S.ButtonGroup>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  afterConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  modalContent: PropTypes.node.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
};
