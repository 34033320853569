import { useState } from 'react';
import { useOutsideClick } from 'hooks';
import PropTypes from 'prop-types';
import SelectMenu from './menu';
import * as S from './styles';
import Fieldbox from '../field-box';

function SelectComponent(props) {
  const {
    onChange,
    field: {
      name,
      value,
    },
    form: {
      setFieldTouched,
      setFieldValue,
    },
    options,
    label,
    disabled,
    onFocus,
    onBlur,
    closeOnSelect,
  } = props;

  const [isShown, setIsShown] = useState(false);

  const handleFocus = () => {
    setIsShown(true);
    onFocus();
  };

  const handleBlur = () => {
    setIsShown(false);
    onBlur();
    setFieldTouched(name, true);
  };

  const selectHandler = (val) => {
    setFieldValue(name, val);
    onChange(val);
    if (closeOnSelect) {
      handleBlur();
    }
  };

  const handleFieldClick = () => {
    if (!isShown) {
      handleFocus();
    } else {
      handleBlur();
    }
  };

  const selectRef = useOutsideClick(() => {
    if (isShown) {
      handleBlur();
    }
  });

  return (
    <S.Select ref={selectRef}>
      <S.Toggle
        type="button"
        onClick={handleFieldClick}
        className={isShown ? 'focused' : null}
        label={label}
        disabled={disabled}
      >
        {value}
        <S.Icon disabled={disabled} className={isShown ? 'opened' : null} />
      </S.Toggle>
      <SelectMenu
        onSelect={selectHandler}
        isShown={isShown}
        options={options}
        selectedValue={value}
      />
    </S.Select>
  );
}

SelectComponent.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  })).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    setFieldError: PropTypes.func,
    setFieldTouched: PropTypes.func,
  }).isRequired,
};

SelectComponent.defaultProps = {
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  label: null,
  disabled: false,
  closeOnSelect: true,
};

export default function FormikSelect(props) {
  return (
    <Fieldbox {...props}>
      <SelectComponent {...props} />
    </Fieldbox>
  );
}
