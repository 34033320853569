import PropTypes from 'prop-types';
import Indicator from 'components/ui-kit/indicator';
import Spinner from 'components/ui-kit/spinner';
import * as S from '../styles';

export default function Button({
  onClick,
  disabled,
  variant,
  type,
  size,
  children,
  color,
  icon,
  indicator,
  stretched,
  isLoading,
  forwardRef,
  className,
}) {
  const withIcon = !!icon;
  return (
    <S.Btn
      className={className}
      onClick={onClick}
      disabled={disabled || isLoading}
      $stretched={stretched}
      variant={variant}
      type={type}
      size={size}
      color={color}
      withIcon={withIcon}
      isLoading={isLoading}
      ref={forwardRef}
    >
      {!isLoading
        ? (
          <>
            {withIcon && icon}
            {children}
          </>
        ) : (<Spinner size="small" color="white" />)}
      {(!!indicator && !isLoading) && <Indicator value={indicator.value} color={indicator.color} />}
    </S.Btn>
  );
}

Button.defaultProps = {
  className: undefined,
  onClick: () => {},
  disabled: false,
  variant: 'contained',
  type: 'button',
  size: 'normal',
  color: 'secondary',
  icon: null,
  indicator: null,
  stretched: false,
  isLoading: false,
  forwardRef: null,
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['contained', 'text']),
  color: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success', 'dark']),
  type: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  stretched: PropTypes.bool,
  icon: PropTypes.node,
  indicator: PropTypes.shape({
    color: PropTypes.oneOf(['red', 'default']),
    value: PropTypes.number,
  }),
  isLoading: PropTypes.bool,
  forwardRef: PropTypes.oneOfType([PropTypes.object]),
};
