import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui-kit/buttons/button';
import { Formik } from 'formik';
import {
  FormikWizardProvider,
  Wizard,
  StepsList,
  Step,
  ButtonsList,
  PreviousButton,
  NextButton,
  SubmitButton,
} from 'formik-wizard-form';
import { useApp, useModal, useToast } from 'hooks';
import { bookingFormFieldType } from 'constants/appointments';
import SentRequestConfirmationBlock from 'components/sent-request-confirmation-block';
import { sendWorkReqeust } from 'api';
import { getTimezoneOffset } from 'helpers/datetime.helper';
import { steps } from './steps';
import validationSchema from './schema';
import stepsValidation from './stepsValidation';
import * as S from './styles';

export default function RequestWorkForm({ form }) {
  const { hideModal } = useModal();
  const { portal } = useApp();
  const { addErrorToast } = useToast();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const getCustomFields = () => form?.fields.filter((f) => f.isEditable);
  const getInitialValues = () => {
    const customFieds = getCustomFields();
    const customFieldsInitialValues = customFieds
      .reduce((acc, { name, fieldType }) => (
        { ...acc, [name]: fieldType === bookingFormFieldType.checkbox ? [] : '' }), {});

    return {
      date: '',
      customTimeRangeEnabled: !!form.customTimeRange,
      servicesEnabled: form?.services?.length > 0,
      portalId: portal.id,
      time: 'Any Time',
      services: '',
      address: '',
      geoData: '',
      unitNumber: '',
      isAddressInArea: '',
      ...customFieldsInitialValues,
    };
  };

  const getValidatorsWithCustomFields = () => {
    const bookingDetailsStepIndex = 1;
    const validators = [stepsValidation];
    const customFieldsNames = getCustomFields().map(({ name }) => name);

    const BookingDetailsStep = ({ errors }) => {
      const errorsArray = customFieldsNames.map((name) => errors[name]);
      return !errorsArray.some((error) => error);
    };
    validators.splice(bookingDetailsStepIndex, 0, BookingDetailsStep);
    return validators;
  };

  const submit = async (values) => {
    const customFieldNames = getCustomFields()
      .map(({ name, labelText }) => { return { name, labelText }; });

    const customAnswers = customFieldNames.map(({ name, labelText }) => {
      const value = values[name];
      return {
        name,
        label: labelText,
        answers: Array.isArray(value) ? value.filter(Boolean) : [value],
      };
    });

    const selectedDate = values.date ? new Date(values.date) : null;
    const data = {
      serviceIds: values.services || [],
      time: values.time,
      date: selectedDate
        ? new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0))
        : null,
      timezoneOffset: getTimezoneOffset(),
      customFields: customAnswers,
      address: {
        location: values.address,
        unitNumber: values.unitNumber,
        geoData: {
          latitude: Number(values.geoData.latitude),
          longitude: Number(values.geoData.longitude),
          placeId: values.geoData.placeId,
        },
      },
    };

    const result = await sendWorkReqeust(portal.id, data);
    if (result.status === 200) {
      setShowConfirmation(true);
    } else {
      addErrorToast('Something went wrong during sending the work request. You can try again later.');
    }
  };

  return (
    <S.Content>
      {!showConfirmation && (
        <Formik
          enableReinitialize
          validateOnBlur
          initialValues={getInitialValues()}
          validationSchema={validationSchema(getCustomFields())}
          onSubmit={submit}
        >
          {(formikProps) => (
            <FormikWizardProvider {...formikProps}>
              {({ getValidators, ...restProps }) => (
                <Wizard {...restProps} className="wizard">
                  <StepsList validators={getValidators(getValidatorsWithCustomFields())}>
                    {steps.map((step, i) => (
                      <Step
                        key={`step-${String(i)}`}
                        component={step.component}
                        title={step.title}
                        form={form}
                        setFieldTouched={formikProps.setFieldTouched}
                      />
                    ))}
                  </StepsList>
                  <ButtonsList>
                    <PreviousButton>
                      <Button>Back</Button>
                    </PreviousButton>
                    <NextButton>
                      <Button stretched color="primary">Next</Button>
                    </NextButton>
                    <SubmitButton>
                      <S.Button>
                        <Button
                          type="submit"
                          color="primary"
                          stretched
                          isLoading={formikProps.isSubmitting}
                        >
                          Submit
                        </Button>
                      </S.Button>
                    </SubmitButton>
                  </ButtonsList>
                </Wizard>
              )}
            </FormikWizardProvider>
          )}
        </Formik>
      )}
      {showConfirmation && <SentRequestConfirmationBlock onSubmit={() => { hideModal(); }} />}
      <S.Logo>
        <S.Text>Powered by</S.Text>
        <S.LogoImage />
      </S.Logo>
    </S.Content>
  );
}

RequestWorkForm.propTypes = {
  form: PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({})),
    services: PropTypes.arrayOf(PropTypes.shape({})),
    customTimeRange: PropTypes.number,
    workingSchedule: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};
