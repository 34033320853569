import styled from 'styled-components';
import { animated } from '@react-spring/web';
import { ReactComponent as ArrowDown } from 'assets/icons/down-selected.svg';
import {
  defaultColor,
  companyColor,
  radius,
  font,
  fluidFont,
} from 'styles/theme';
import { FormControlStlyes } from '../styles';

export const Select = styled.div`
  position: relative;
`;

export const Toggle = styled.button.attrs({ type: 'button' })`
  ${FormControlStlyes};
  background: none;
  cursor: pointer;
  color: ${companyColor('font')};
  text-transform: capitalize;
  text-align: left;
  padding-left: 1rem;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : null)};
`;

export const Icon = styled(ArrowDown)`
  position: absolute;
  right: 1rem;
  top: 15px;
  transform: rotate(0);
  transition: transform 190ms ease-in-out;
  will-change: transform;

  &.opened {
    transform: rotate(180deg);
  }
  fill: ${({ disabled }) => (
    disabled ? defaultColor('metallic') : defaultColor('dark')
  )};
`;

export const ListItem = styled.button.attrs({ type: 'button' })`
  display: block;
  padding: 1rem;
  cursor: pointer;
  color: ${companyColor('font')};
  font-family: ${({ isActive }) => (
    isActive ? font('semibold') : font('regular')
  )};
  ${fluidFont('12px', '14px')};
  text-align: left;
  border: none;
  background: none;
  width: 100%;
  transition: background-color 190ms linear;
  will-change: color;

  &:hover {
    background-color: ${defaultColor('backgroundLight')};
  }
`;

export const List = styled(animated.ul)`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0.5rem 0 0 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-width: ${({ $fullWidth }) => ($fullWidth ? null : '215px')};
  border-radius: ${radius('small')};
  background-color: ${defaultColor('white')};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 8px 16px rgba(0, 0, 0, 0.24);
  z-index: 20;

  & li:first-child ${ListItem} {
    border-top-left-radius: ${radius('small')};
    border-top-right-radius: ${radius('small')};
  }

  & li:last-child ${ListItem} {
    border-bottom-left-radius: ${radius('small')};
    border-bottom-right-radius: ${radius('small')};
  }
`;

export const MultiItem = styled.div`
  background-color: ${companyColor('background')};
  color: ${companyColor('font')};
  font-family: ${({ isActive }) => (
    isActive ? font('semibold') : font('regular')
  )};
  border-radius: ${radius('small')};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  height: fit-content;
  padding: 2px 6px;
  white-space: nowrap;
  overflow: hidden;
  `;

export const MultiItemsContainer = styled.div`
  display: flex;
  gap: 3px;
  max-width: 95%;
`;
export const TruncatedText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Close = styled.a`
  display: flex;
  cursor: pointer;
  background: none;
  border: none;
  & > svg {
    fill: ${defaultColor('black')};
    &:hover {
      fill: ${defaultColor('red')};
    }
  }
`;

export const PlaceHolder = styled.span`
  color: ${defaultColor('metallic')};
`;

export const ErrorMessage = styled.div`
    display: block;
    position: inherit;
    font-family: ${font('regular')};
    ${fluidFont('11px', '12px')};
    color: ${defaultColor('red')};
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 4px;
`;
