import styled from 'styled-components';

export const AttachmentImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 480px;
  max-height: 480px;
  border-radius: 8px;

  object-fit: contain;

  @media (max-width: 768px) {
    max-width: 100%;
    max-height: 100%;
  }
`;
