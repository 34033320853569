import styled from 'styled-components';
import {
  companyColor,
  defaultColor,
  fluidFont,
} from 'styles/theme';
import { ReactComponent as Icon } from 'assets/icons/book-online.svg';

export const Text = styled.div`
  color: ${companyColor('font')};
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  text-align: center;
  ${fluidFont('13px', '14px')};
  background: ${defaultColor('white')};;
  padding: 15px;
  border-radius: 6px;
  height: fit-content;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.1rem;
`;

export const BookIcon = styled(Icon)`
  fill: ${companyColor('font')};
  stroke: ${companyColor('font')};
`;
