import { useField } from 'formik';
import { useTransition } from 'react-spring';
import * as S from './styles';

function MultiSelectMenu({
  isShown,
  name,
  options,
  onAdd,
  fullWidth,
}) {
  const [field] = useField(name);
  const { value } = field;

  const transitions = useTransition(isShown, {
    config: {
      duration: 210,
    },
    from: { opacity: 0, transform: 'translate3d(0, 20px, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(0, 20px, 0)' },
  });

  const shouldDisplayItem = (option) => {
    if (!value) return true;
    return !value.includes(option.value);
  };

  return transitions((styles, item) => item && (
    <S.List $fullWidth={fullWidth} style={styles}>
      {options.map((option, index) => (
        <li
          key={`${option.value}${String(index)}`}
          style={{ display: (shouldDisplayItem(option)) ? 'list-item' : 'none' }}
        >
          <S.ListItem
            onClick={() => onAdd(option.value)}
          >
            {option.label}
          </S.ListItem>
        </li>
      ))}
    </S.List>
  ));
}

export default MultiSelectMenu;
