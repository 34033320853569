import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  font,
  defaultColor,
  companyColor,
  radius,
  fluidFont,
} from 'styles/theme';

// eslint-disable-next-line default-param-last
const getBackground = (isHover = false, variant) => (
  ({ color }) => {
    switch (color) {
      case 'primary':
        return companyColor('main', isHover ? 0.5 : 1);
      case 'danger':
        return defaultColor(isHover ? 'redDark' : 'red');
      case 'secondary':
        return (
          variant === 'text'
            ? companyColor('font')
            : defaultColor(isHover ? 'background' : 'backgroundLight')
        );
      case 'success':
        return defaultColor(isHover ? 'greenDark' : 'green');
      case 'dark':
      default:
        return companyColor('font');
    }
  }
);

const getFontColor = () => (
  ({ color }) => {
    switch (color) {
      case 'primary':
      case 'danger':
      case 'success':
      case 'dark':
        return defaultColor('white');
      case 'secondary':
      default:
        return companyColor('font');
    }
  }
);

const containedVariant = css`
  background-color: ${({ variant }) => (
    variant === 'contained' ? getBackground() : 'none'
  )};
  color: ${getFontColor()};

  &:hover {
    background-color: ${getBackground(true)};
  }
`;

const textVariant = css`
  background: none;
  color: ${getBackground(false, 'text')};

  &:hover {
    background-color: ${defaultColor('backgroundLight')};
  }
`;

const withIconStyles = css`
  padding: 1em 1.5em;
  padding-left: 1em;
`;

const withoutIconStyles = css`
  padding: 1em 1.5em;
`;

const commonDisplayStyles = css`
  ${({ $withIcon }) => ($withIcon ? withIconStyles : withoutIconStyles)};
  ${({ variant }) => (
    variant === 'text' ? textVariant : containedVariant
  )};
  height: ${({ isLoading }) => (isLoading ? '40px' : 'auto')};
  width: ${({ $stretched }) => ($stretched ? '100%' : 'initial')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  display: flex;
  justify-content: space-around;
  align-items: center;
  max-height: 40px;
  gap: 0.5rem;
  position: relative;
  border-radius: ${radius('small')};
  font-family: ${font('semibold')};
  font-weight: 600;
  ${fluidFont('11px', '13px')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'initial')};
  transition: background-color 210ms linear, box-shadow 210ms ease-in-out;
  &:active {
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.16);
  }
  &:focus-visible {
    outline: 2px solid ${defaultColor('blue')};
  }
`;

export const Btn = styled.button`
  ${commonDisplayStyles};
  cursor: pointer;
  border: none;
  outline: none;
`;

export const RouterLink = styled(Link)`
  ${commonDisplayStyles};
  text-decoration: none;
`;

export const NativeLink = styled.a.attrs(({
  newTab,
}) => {
  if (newTab) {
    return ({
      target: '_blank',
    });
  }
  return null;
})`
  ${commonDisplayStyles};
  text-decoration: none;
`;
