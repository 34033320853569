import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
} from 'components/ui-kit/form';
import { Field as FormikField } from 'formik';
import { getTimeIntervals } from 'helpers/datetime.helper';
import FormikDatepicker from 'components/ui-kit/form/formik-datepicker';
import FormikSelect from 'components/ui-kit/form/formik-select';
import FormikMultiSelect from 'components/ui-kit/form/formik-multi-select';
import LocationInput from 'components/ui-kit/form/location-input';
import { useQuery } from 'react-query';
import { isInServiceArea } from 'api';
import * as S from '../styles';

export default function GeneralDetailsStep(props) {
  const {
    values,
    form,
    setFieldValue,
  } = props;

  useQuery(
    [
      'serviceAreaCheck',
      values.portalId,
      values.geoData?.latitude,
      values.geoData?.longitude,
    ],
    isInServiceArea,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: (
        !!values.geoData?.latitude
      && !!values.geoData?.longitude
      ),
      onSuccess: ({ data: { isInServiceArea: flag } }) => {
        setFieldValue('isAddressInArea', flag);
      },
    },
  );

  const getTimeRangeOptions = () => {
    if (values.customTimeRangeEnabled && values.date) {
      const date = typeof values.date === 'string' ? new Date(values.date) : values.date;
      const day = date.getDay();
      const schedule = form.workingSchedule.find((x) => x.weekDay === day);
      const timeRanges = getTimeIntervals(schedule.start, schedule.end, form.customTimeRange * 60);
      return timeRanges.map((o) => ({ value: o, label: o }));
    }

    return form.fields.find((x) => x.name === 'Time').options.map((o) => ({ label: o, value: o }));
  };

  const formatServicesOptions = (options) => (
    options ? options.map((option) => ({
      label: option.name,
      value: option.id,
    })) : []);

  const isDayDisabled = (date) => {
    const workingDays = form.workingSchedule.filter((day) => day.isWorkingDay).map((day) => day.weekDay);
    const isDisabled = !workingDays.includes(date.getDay());
    return isDisabled;
  };

  return (
    <S.Content>
      <S.Header>
        Book Online
      </S.Header>
      <S.FieldContainer>
        <S.Label>If available, what day works best for you?</S.Label>
        <FormikField
          name="date"
          component={FormikDatepicker}
          selectRange={false}
          shouldDisableDay={isDayDisabled}
          isGap={false}
        />
      </S.FieldContainer>
      <S.FieldContainer>
        <S.Label>What are you preferred arrival times?</S.Label>
        <FormikField
          name="time"
          placeholder="Any time"
          options={getTimeRangeOptions()}
          component={FormikSelect}
          label="Arrival time"
          disabled={!values.date}
          closeOnSelect
          isGap={false}
        />
      </S.FieldContainer>
      {form.services && form.services.length > 0 && (
      <S.FieldContainer>
        <S.Label>Services</S.Label>
        <FormikField
          name="services"
          placeholder="Select Services"
          options={formatServicesOptions(form.services)}
          component={FormikMultiSelect}
          closeOnSelect={false}
          hideLabel
          isGap={false}
        />
      </S.FieldContainer>
      )}
      <S.Divider />
      <S.FieldContainer>
        <S.Label>Specify property</S.Label>
        <FormikField
          name="address"
          component={LocationInput}
          label="Property"
          isGap={false}
        />
      </S.FieldContainer>
      <S.FieldContainer>
        <S.Label>Specify Unit/Suite/Apt. number (optional)</S.Label>
        <FormikField
          name="unitNumber"
          component={Input}
          label="Unit Number"
          isGap={false}
        />
      </S.FieldContainer>
    </S.Content>
  );
}

export const workingScheduleItemPropTypes = PropTypes.shape({
  start: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
  end: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
  isWorkingDay: PropTypes.bool.isRequired,
  weekDay: PropTypes.number.isRequired,
});

GeneralDetailsStep.propTypes = {
  form: PropTypes.shape({
    customTimeRange: PropTypes.number,
    workingSchedule: PropTypes.arrayOf(workingScheduleItemPropTypes).isRequired,
    services: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })),
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  values: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.string,
    services: PropTypes.arrayOf(PropTypes.string),
    address: PropTypes.string,
    geoData: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    unitNumber: PropTypes.string,
    customTimeRangeEnabled: PropTypes.bool,
    portalId: PropTypes.string,
    isAddressInArea: PropTypes.bool,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.string,
    services: PropTypes.string,
    address: PropTypes.string,
    unitNumber: PropTypes.string,
    isInServiceArea: PropTypes.string,
  }),
};

GeneralDetailsStep.defaultProps = {
  errors: {},
};
