import PropTypes from 'prop-types';
import Button from 'components/ui-kit/buttons/button';
import { useApp } from 'hooks';
import { ReactComponent as Icon } from 'assets/icons/help-sent-icon.svg';
import * as S from './styles';

export default function SentRequestConfirmationBlock({ onSubmit }) {
  const { user } = useApp();
  return (
    <S.ConfirmationBlock>
      <S.ImageContainer>
        <S.Image>
          <Icon />
        </S.Image>
      </S.ImageContainer>

      <S.Response>We&apos;ve received your request and someone from our team will be in touch soon via:</S.Response>
      <S.ContanctsSection>
        <S.TextSilver>{user.email}</S.TextSilver>
        {user.phoneNumber && <S.TextSilver>{user.phoneNumber}</S.TextSilver>}
      </S.ContanctsSection>
      <S.GotIt>
        <Button stretched color="primary" onClick={() => { onSubmit(); }}>Got it!</Button>
      </S.GotIt>
    </S.ConfirmationBlock>
  );
}

SentRequestConfirmationBlock.propTypes = {
  onSubmit: PropTypes.func,
};

SentRequestConfirmationBlock.defaultProps = {
  onSubmit: () => {},
};
