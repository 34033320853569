import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/ui-kit/modal';
import { Group } from 'components/ui-kit/button-group/styles';

import { AttachmentImage } from './styles';

const ViewAttachmentModal = ({ imageUrl }) => {
  return (
    <Modal title="View Attachment">
      <Group justifyContent="center">
        <AttachmentImage src={imageUrl} alt="attachment" />
      </Group>
    </Modal>
  );
};

ViewAttachmentModal.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export default ViewAttachmentModal;
