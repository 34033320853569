import styled, { keyframes } from 'styled-components';
import {
  companyColor,
  defaultColor,
} from 'styles/theme';

const loading = keyframes`
  100% {
    transform: translateY(-50%) rotate(-180deg);
  }
`;

export const Prefix = styled.div`
  color: ${defaultColor('grey')};

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 24px;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  bottom: -1.1rem;
  color: ${defaultColor('red')};
  font-size: 0.8rem;
`;

export const Fieldbox = styled.div`
  display: block;
  min-height: 50px;
  position: relative;
  margin-bottom: ${({ isGap }) => (isGap ? '42px' : '22px')};

  & input,
  & textarea,
  & > select {
    border-color: ${({ isError }) => (isError ? defaultColor('red') : defaultColor('light'))};

    &:focus {
      border-color: ${({ isError }) => (isError ? defaultColor('red') : companyColor('font'))};
    }

    &:disabled {
      color: ${defaultColor('grey')};
    }
  }
`;

export const FieldIcon = styled.div`
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  color: ${defaultColor('grey')};
  pointer-events: ${({ isIconClickable }) => (isIconClickable ? 'auto' : 'none')};
`;

export const FieldLoader = styled(FieldIcon)`
  animation: ${loading} 1s linear infinite;
`;
