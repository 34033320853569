import BookingDetailsStep from './booking-details-step';
import GeneralDetailsStep from './general-details-step';

export const steps = [
  {
    title: '1. General',
    component: GeneralDetailsStep,
  },
  {
    title: '2.Booking details',
    component: BookingDetailsStep,
  },
];
