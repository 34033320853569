import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import * as S from './styles';

export default function MultiItem({
  value,
  label,
  onRemove,
}) {
  return (
    <S.MultiItem title={label}>
      <S.TruncatedText>{label}</S.TruncatedText>
      <S.Close onClick={() => onRemove(value)}>
        <CloseIcon />
      </S.Close>
    </S.MultiItem>
  );
}

MultiItem.propTypes = {
  onRemove: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};
