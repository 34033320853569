import { useTransition } from 'react-spring';
import * as S from './styles';

function SelectMenu(props) {
  const {
    onSelect,
    isShown,
    options,
    selectedValue,
    fullWidth,
  } = props;

  const transitions = useTransition(isShown, {
    config: {
      duration: 210,
    },
    from: { opacity: 0, transform: 'translate3d(0, 20px, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(0, 20px, 0)' },
  });

  return transitions((styles, item) => item && (
    <S.List $fullWidth={fullWidth} style={styles}>
      {options.map((option, index) => (
        <li
          key={`${option.value}${String(index)}`}
          style={{ display: 'list-item' }}
        >
          <S.ListItem
            onClick={() => onSelect(option.value)}
            isActive={selectedValue === option.value}
          >
            {option.label}
          </S.ListItem>
        </li>
      ))}
    </S.List>
  ));
}

export default SelectMenu;
