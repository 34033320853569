import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Typography from 'components/ui-kit/typography';
import Stack from 'components/ui-kit/stack';
import Group from 'components/ui-kit/group';
import NoTextCheckbox from 'components/ui-kit/no-text-checkbox';
import Tooltip from 'components/ui-kit/tooltip';
import ViewAttachmentModal from 'components/view-attachment-modal';

import { useModal } from 'hooks';

import { QuestionIcon } from 'assets/icons';

import LineItemAmount from './components/line-item-amount';

import * as S from './styles';

const LineItemsSection = ({
  lineItems,
  sectionLineItems,
  subtitle,
  columns,
  setLineItems,
  isOptional,
  disabled,
}) => {
  const { showModal } = useModal();

  if (!lineItems.length) {
    return null;
  }

  const handleCheckboxChange = (newValue, lineItemKey) => {
    const updatedLineItems = lineItems.map((item) => {
      if (item.key === lineItemKey) {
        return {
          ...item,
          optionalState: {
            ...item.optionalState,
            isAccepted: newValue,
          },
        };
      }

      return item;
    });

    setLineItems(updatedLineItems);
  };

  return (
    <Stack>
      <Group alignItems="center">
        <Typography bold fontFamily="regular" fontSize={14}>{subtitle}</Typography>

        {isOptional && !disabled && (
          <Tooltip text="Items below are optional. You can check the items to include">
            <QuestionIcon />
          </Tooltip>
        )}
      </Group>

      <S.Table>
        <tbody>
          <tr>
            {columns.map((col, i) => <S.TableHeader key={`${String(i)}`}>{col.label}</S.TableHeader>)}
          </tr>

          {sectionLineItems.map((lineItem, index) => {
            const { optionalState } = lineItem;

            const isChecked = optionalState
            && (optionalState.isAccepted
            || (optionalState.isAccepted === null && optionalState.isRecommended));

            return (
              <tr key={lineItem.key || index}>
                {columns.map((col, i) => {
                  const isAmount = col.name === 'amount';
                  const isName = col.name === 'name';

                  if (isAmount) {
                    return (
                      <S.Cell key={`${String(i)}`} isBold sizes={col.size}>
                        <LineItemAmount lineItem={lineItem} />
                      </S.Cell>
                    );
                  }

                  if (isName) {
                    const handlePreview = () => {
                      if (!lineItem.imageUrls?.publicImageUrl) return;

                      showModal(ViewAttachmentModal, {
                        imageUrl: lineItem.imageUrls.publicImageUrl,
                      });
                    };

                    return (
                      <S.Cell key={`${String(i)}`} sizes={col.size}>
                        <Group w="100%" alignItems="center">
                          <Stack w="100%">
                            <Group alignItems="center" gap={16}>
                              {isOptional && (
                                <NoTextCheckbox
                                  disabled={disabled}
                                  checked={isChecked}
                                  onChange={(newValue) => handleCheckboxChange(newValue, lineItem.key)}
                                />
                              )}

                              <Typography fontFamily="regular" fontSize={14} bold>{lineItem[col.name]}</Typography>
                            </Group>

                            <S.LineItemDescription>
                              {lineItem.description}
                            </S.LineItemDescription>
                          </Stack>

                          {lineItem.imageUrls?.publicImageUrl ? (
                            <S.StyledImageWrapper>
                              <S.StyledImage src={lineItem.imageUrls.publicImageUrl} alt={lineItem.name} />

                              <S.HoverOverlay>
                                <S.IconButton type="button" onClick={handlePreview}>
                                  <Tooltip text="View" icon={<S.ShowIcon />} />
                                </S.IconButton>
                              </S.HoverOverlay>
                            </S.StyledImageWrapper>
                          ) : (
                            <S.Placeholder />
                          )}
                        </Group>
                      </S.Cell>
                    );
                  }

                  return (
                    <S.Cell key={`${String(i)}`} sizes={col.size}>
                      {lineItem[col.name]}
                    </S.Cell>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </S.Table>
    </Stack>
  );
};

LineItemsSection.propTypes = {
  subtitle: PropTypes.string.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sectionLineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  setLineItems: PropTypes.func,
  isOptional: PropTypes.bool,
  disabled: PropTypes.bool,
};

LineItemsSection.defaultProps = {
  setLineItems: () => {},
  isOptional: false,
  disabled: false,
};

export default memo(LineItemsSection);
