import Modal from 'components/ui-kit/modal';
import { useApp } from 'hooks';
import { useQuery } from 'react-query';
import { getWorkReqeustForm } from 'api';
import RequestWorkForm from './form';

export default function RequestWorkModal() {
  const { portal } = useApp();
  const {
    isError, isLoading, isFetching, data,
  } = useQuery(
    ['requestWorkForm', portal?.id],
    getWorkReqeustForm,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!portal?.id,
    },
  );

  if (isError) {
    return 'Error occured while fetching appointment';
  }
  if (isLoading || isFetching) {
    return 'loader';
  }
  const form = data?.data;
  return (
    <Modal isClosable={false}>
      <RequestWorkForm form={form} />
    </Modal>
  );
}
