import { useState } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { useTransition } from '@react-spring/core';
import { useOutsideClick } from 'hooks';
import { useFormikContext } from 'formik';
import { format as formatDate } from 'date-fns';

import * as S from './styles';
import 'react-calendar/dist/Calendar.css';
import Fieldbox from '../field-box';

const defaultDateFormat = 'MMM dd, yyyy';

function DatepickerComponent(props) {
  const {
    field: {
      name,
      value,
    },
    field,
    label,
    minDate,
    maxDate,
    selectRange,
    onFocus,
    onBlur,
    onChange,
    format,
    shouldDisableDay,
    ...restProps
  } = props;

  const formatter = (val) => {
    if (!val) return '';

    const date = new Date(val);
    const formatted = formatDate(date, format || defaultDateFormat);
    return formatted;
  };

  const formikContext = useFormikContext();

  const {
    setFieldTouched,
    setFieldValue,
  } = formikContext;
  const [isShown, setIsShown] = useState(false);

  const handleBlur = () => {
    setIsShown(false);
    onBlur();
    if (isShown) {
      setFieldTouched(name, true);
    }
  };

  const inputRef = useOutsideClick(() => {
    handleBlur();
  });

  const transitions = useTransition(isShown, {
    config: {
      duration: 210,
    },
    from: { opacity: 0, transform: 'translateY(20px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(20px)' },
    onRest: () => setIsShown((prevIsShown) => (!!prevIsShown)),
  });

  const handleFocus = () => {
    setIsShown(true);
    onFocus();
  };

  const handleChange = (v) => {
    const date = new Date(v);
    setFieldValue(name, date.toISOString());
  };

  const handleBackspace = () => {
    setFieldValue(name, '');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      handleBackspace(e);
    }
  };

  return (
    <div
      ref={inputRef}
    >
      <S.Input
        {...restProps}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        onChange={noop}
        label={label}
        value={formatter(value)}
      />
      {transitions((styles, item) => item && (
        <S.PickerMenu style={styles}>
          <S.CalendarBox
            selectRange={selectRange}
            defaultValue={value ? new Date(value) : new Date()}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleChange}
            tileDisabled={({ date }) => shouldDisableDay(date)}
          />
        </S.PickerMenu>
      ))}
    </div>

  );
}

DatepickerComponent.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  selectRange: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  format: PropTypes.string,
  shouldDisableDay: PropTypes.func,
};

DatepickerComponent.defaultProps = {
  label: '',
  minDate: null,
  maxDate: null,
  selectRange: false,
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
  format: '',
  shouldDisableDay: () => false,
};

export default function FormikDatepicker(props) {
  return (
    <Fieldbox
      {...props}
    >
      <DatepickerComponent {...props} />
    </Fieldbox>
  );
}
