import { useState } from 'react';
import { useOutsideClick } from 'hooks';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import MultiSelectMenu from './menu';
import MultiItem from './item';
import * as S from './styles';
import Fieldbox from '../field-box';

const mapToObj = (arr) => {
  const obj = {};
  arr.forEach((item) => {
    obj[item.value] = item.label;
  });

  return obj;
};

function MultiSelectComponent(props) {
  const {
    onChange,
    onRemove,
    onAdd,
    field: {
      name,
    },
    options,
    label,
    disabled,
    onFocus,
    onBlur,
    closeOnSelect,
  } = props;

  const [isShown, setIsShown] = useState(false);
  const optionsMap = mapToObj(options);

  const [field, , helpers] = useField(name);
  const { value } = field;
  const { setValue, setTouched } = helpers;

  const handleFocus = () => {
    setIsShown(true);
    onFocus();
  };

  const handleBlur = () => {
    setIsShown(false);
    onBlur();
    setTouched(true);
  };

  const handleFieldClick = () => {
    if (!isShown) {
      handleFocus();
    } else {
      handleBlur();
    }
  };

  const selectRef = useOutsideClick(() => {
    if (isShown) {
      handleBlur();
    }
  });

  const onChangeHandler = (val, isItemAdded = true) => {
    onChange(val);
    if (closeOnSelect && isItemAdded) {
      handleBlur();
    }
  };

  const handleSelectItem = (val) => {
    const selected = value ? [...value, val] : [val];
    setValue(selected);
    if (selected.length === options.length) {
      handleBlur();
    }
    onAdd(val);
    onChangeHandler(val);
  };

  const handleRemoveItem = (val) => {
    const selected = value.filter((item) => item !== val);
    setValue(selected.length ? selected : '');
    onRemove(val);
    onChangeHandler(val, false);
  };

  const renderSelected = () => !!value
  && (
    <S.MultiItemsContainer>
      {value
        .map((val, index) => {
          const itemLabel = optionsMap[val];
          return (
            <MultiItem
              key={`${val}-${String(index)}`}
              onRemove={handleRemoveItem}
              value={val}
              label={itemLabel}
            />
          );
        })}
    </S.MultiItemsContainer>
  );

  return (
    <S.Select ref={selectRef}>
      <S.Toggle
        type="button"
        onClick={handleFieldClick}
        className={isShown ? 'focused' : null}
        label={label}
        disabled={disabled}
      >
        {renderSelected()}
        <S.Icon disabled={disabled} className={isShown ? 'opened' : null} />
      </S.Toggle>
      <MultiSelectMenu
        onAdd={handleSelectItem}
        name={name}
        isShown={isShown}
        options={options}
      />
    </S.Select>
  );
}

MultiSelectComponent.propTypes = {
  onChange: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  })).isRequired,
  disabled: PropTypes.bool,
  closeOnSelect: PropTypes.bool,
};

MultiSelectComponent.defaultProps = {
  onChange: () => {},
  onAdd: () => {},
  onRemove: () => {},
  onFocus: () => {},
  onBlur: () => {},
  label: null,
  disabled: false,
  closeOnSelect: true,
};

export default function FormikMultiSelect(props) {
  return (
    <Fieldbox {...props}>
      <MultiSelectComponent {...props} />
    </Fieldbox>
  );
}
