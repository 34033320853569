import PropTypes from 'prop-types';
import Navigation from 'components/navigation';
import CompanyInfo from 'components/company-info';
import RequestWork from 'components/request-work';
import Logo from 'components/logo';
import * as S from './styles';

export default function Sidebar({ isShownMobile }) {
  return (
    <S.Box isShow={isShownMobile}>
      <Logo />
      <Navigation />
      <RequestWork />
      <CompanyInfo />
    </S.Box>
  );
}

Sidebar.propTypes = {
  isShownMobile: PropTypes.bool.isRequired,
};
